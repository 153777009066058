<template>
  <v-bottom-sheet
    class="font font-weight-medium"
    inset
    v-model="state"
    persistent
  >
    <v-card tile>
      <div v-if="details" class="d-flex flex-row flex-grow-1">
        <div class="d-flex flex-column flex-grow-1 mx-3">
          <span class="font-weight-bold ft font-size-md ft mx-1 px-3 pt-3"
            >List Of Deliveries</span
          >
          <span class="font-weight-bold ft text-sm ft mx-1 px-3  pt-1"
            >Order Reference : {{ details.orderReference }}
          </span>
          <span
            v-if="details && details.productId"
            class="font-weight-bold text-sm ft mx-1 px-3 pb-1 pt-1"
            >Product Name : {{ details.productId.name }}
          </span>
          <span
            v-if="details.carId"
            class="font-weight-bold ft text-sm ft mx-1 px-3 pb-1"
            >Vehicle Number : {{ details.carId.registrationNumber }}
          </span>
          <span class="font-weight-bold ft text-sm ft mx-1 px-3 pb-1"
            >Trip Number :
            {{ details.tripNumber ? details.tripNumber : "No trip number" }}
          </span>
          <span class="font-weight-bold ft text-sm ft mx-1 px-3 pb-2"
            >Date : {{ details.date | timestamp("Do MMMM, YYYY") }}
          </span>
        </div>
        <div class="d-flex flex-column flex-grow-1 mx-3">
          <span class="font-weight-bold ft text-sm ft mx-1 px-3 pt-4"
            >Loading Date :
            <span v-if="details.loadingDate">{{
              details.loadingDate | timestamp("Do MMMM, YYYY")
            }}</span
            ><span v-else>{{ "No Loading Date" }}</span>
          </span>
          <span
            v-if="details && details.orderNumber"
            class="font-weight-bold text-sm ft mx-1 px-3 pb-1 pt-1"
            >Vivo Order Number : {{ details.orderNumber }}
          </span>
          <span class="font-weight-bold ft text-sm ft mx-1 px-3 pt-1 pb-1"
            >Cheque Number :
            {{
              details.chequeId ? details.chequeId.number : "No cheque number"
            }}
          </span>
          <span
            v-if="details.chequeId"
            class="font-weight-bold ft text-sm ft mx-1 px-3 pb-1"
            >Cheque Amount :
            {{
              details.chequeId.amount
                | currencyFormat(currency.length > 0 ? currency[0].code : "GHS")
            }}
          </span>
        </div>

        <v-btn @click="close" small right top absolute fab color="primary">
          <i class="material-icons">close</i>
        </v-btn>
      </div>
      <template v-if="pageLoading">
        <SkeletonPreloader type="table" />
      </template>
      <v-card-text v-else>
        <v-simple-table
          v-if="details && details.productDeliveries.length > 0"
          class="font font-weight-medium box-shadow-light"
        >
          <template v-slot:default>
            <thead style="width: 100%;">
              <tr>
                <th class="text-left ft">
                  Date
                </th>
                <th class=" text-left ft">
                  Branch
                </th>
                <th class="text-left ft">
                  Quantity
                </th>
                <th class="text-left ft">
                  Delivery Status
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in details.productDeliveries"
                :key="index"
              >
                <td class="ft font-size-sm font-weight-medium">
                  {{ item.date | timestamp }}
                </td>
                <td class="ft font-size-sm font-weight-medium">
                  {{ item.branchId ? item.branchId.name : "No Branch" }}
                </td>
                <td class="ft font-weight-medium font-size-sm">
                  {{ item.quantity }}
                </td>
                <td class="ft font-weight-medium font-size-sm">
                  <v-chip
                    label
                    :color="
                      item.isDeliveryAccepted
                        ? '#9efb9e66'
                        : 'rgb(247, 189, 172)'
                    "
                    class="ft font-weight-medium mr-2 text-uppercase"
                    small
                  >
                    <span
                      :style="{
                        color: item.isDeliveryAccepted
                          ? 'rgba(3, 91, 3, 0.83)'
                          : 'rgb(238, 78, 0)'
                      }"
                      class="font-weight-bold"
                      >{{
                        item.isDeliveryAccepted ? "Completed" : "Not Completed"
                      }}</span
                    >
                  </v-chip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div v-else>
          <EmptyPage
            :image="require(`@/assets/pallets.svg`)"
            title="No deliveries have been made for this order yet."
            subTitle="All pending and approved deliveries will show up here."
          />
        </div>
      </v-card-text>
    </v-card>
  </v-bottom-sheet>
</template>
<script>
import { timestamp } from "../../../../../filters/timestamp";
import { currencyFormat } from "../../../../../filters/currencyFormat";
export default {
  name: "ViewDeliveriesBottomSheet",
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    state: {
      type: Boolean,
      default: false
    },
    pageLoading: {
      type: Boolean,
      default: false
    },
    details: {
      type: Object,
      default: null
    },
    currency: {
      type: Array,
      default: () => []
    }
  },
  components: {
    SkeletonPreloader: () =>
      import(`../../../../../components/SkeletonPreloader`),
    EmptyPage: () => import(`../../../../../components/EmptyPage`)
  },
  filters: {
    timestamp,
    currencyFormat
  },
  data: () => ({
    headers: [
      { text: "Branch", value: "branchId" },
      { text: "Product", value: "productId" },
      { text: "Quantity (Ltr)", value: "quantity" }
    ]
  }),
  methods: {
    close() {
      this.$emit("close", "alert");
    }
  }
};
</script>
